(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("three"));
	else if(typeof define === 'function' && define.amd)
		define("potree", ["three"], factory);
	else if(typeof exports === 'object')
		exports["potree"] = factory(require("three"));
	else
		root["potree"] = factory(root["three"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 